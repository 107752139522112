import './Test.css';

const Test = () => {
  return (
    <div className="container">
      welcome
    </div>
  )
}

export default Test;